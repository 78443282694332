<!--
 * @Description: 发票中心 invoiceCenter
 * @Author: yanxiao
 * @Github:
 * @Date: 2021-06-07 11:24:45
 * @LastEditors: yanxiao
-->
<template>
  <div class='main'>
    <van-nav-bar title="发票中心"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />

    <van-row class="content"
             @click="toPage('/paymentHistory')">
      <van-col span='19'>
        <van-row style="margin:16px 0 0 12px">停车费用开票</van-row>
        <van-row style="margin:13px 0 0 12px">
          <van-col class="text"
                   span='7'>往期订单</van-col>
          <van-col class="text">一键开票</van-col>
        </van-row>
      </van-col>
      <van-col span='5'>
        <van-image height="46"
                   width="46"
                   style="margin-top:22px"
                   :src="require('@/assets/parkingPayment/invoice.png')" />
      </van-col>
    </van-row>
    <van-row class="content"
             @click="toPage('/invoiceHistory')">
      <van-col span='19'>
        <van-row style="margin:16px 0 0 12px">开票记录</van-row>
        <van-row style="margin:13px 0 0 12px">
          <van-col class="text"
                   span='7'>历史开票</van-col>
          <van-col class="text">快速查看</van-col>
        </van-row>
      </van-col>
      <van-col span='5'>
        <van-image height="55"
                   width="46"
                   style="margin-top:22px"
                   :src="require('@/assets/parkingPayment/invoiceRecord.png')" />
      </van-col>
    </van-row>
    <!-- <van-cell title="停车费用开票"
              is-link
              @click="toPage('/paymentHistory')" />
    <van-cell title="开票记录"
              is-link
              @click="toPage('/invoiceHistory')" /> -->
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {

    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    toPage (path, query = {}) {
      this.$router.push({ path, query })
    },
    // 返回上月
    handleClickTopBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.main {
  width: 100%;
  height: 100%;
  .content {
    width: 93.6%;
    height: 90px;
    background: #ffffff;
    border-radius: 5px;
    margin: 12px 3.2% 0 3.2%;
    font-size: 17px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    .text {
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #666666;
    }
  }
}
</style>
